<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="$t('complaints.complaints')"
                icon="mdi-badge-account-alert-outline"
            />
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="2">
                        <v-list>
                            <v-list-item-group
                                @change="refreshTable"
                                v-model="selectedState"
                                color="primary"
                                mandatory
                            >
                                <div v-for="(item, i) in listItems" :key="i">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon
                                                :color="item.color"
                                                v-text="item.icon"
                                            ></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-text="item.text"
                                            ></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider v-if="i == 3"></v-divider>
                                </div>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="12" sm="10">
                        <v-data-table
                            :headers="headers"
                            :items="complaints"
                            :search="search"
                            :loading="loading"
                            class="pa-5"
                            style="overflow: hidden;"
                            :loading-text="$t('loading')"
                        >
                            <template v-slot:top>
                                <!-- Search bar -->
                                <v-text-field
                                    dense
                                    outlined
                                    :loading="loading"
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('search')"
                                    single-line
                                    hide-details
                                ></v-text-field>
                                <!-- View Complaint -->
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-row no-gutters class="text-end">
                                    <v-col cols="6"
                                        ><v-tooltip top>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-icon
                                                    class="mr-2"
                                                    @click="viewItem(item)"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span> {{ $t("preview") }} </span>
                                        </v-tooltip></v-col
                                    >
                                </v-row>
                            </template>
                            <template v-slot:[`item.details`]="{ item }">
                                <v-tooltip :max-width="600" top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">{{
                                            item.details | truncate(40)
                                        }}</span>
                                    </template>
                                    <span>{{
                                        item.details | truncate(250)
                                    }}</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.complaintDate`]="{ item }">
                                {{
                                    item.complaintDate
                                        | moment("yyyy-MM-DD hh:mm A")
                                }}
                            </template>
                            <template v-slot:[`item.duration`]="{ item }">
                                <v-chip color="primary" outlined>{{
                                    item.stateDate | fromNow
                                }}</v-chip>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import PageHeader from "../../components/PageHeader.vue";
import moment from "moment";

export default {
    components: { PageHeader },
    filters: {
        fromNow(date) {
            return moment(date).fromNow();
        }
    },
    data() {
        return {
            selectedState: 0,
            listItems: [
                {
                    text: this.$t("complaints.types.new"),
                    icon: "mdi-email",
                    value: 0,
                    color: "primary"
                },
                {
                    text: this.$t("complaints.types.open"),
                    icon: "mdi-email-open",
                    value: 1,
                    color: "blue"
                },
                {
                    text: this.$t("complaints.types.pending"),
                    icon: "mdi-email-alert",
                    value: 2,
                    color: "orange"
                },
                {
                    text: this.$t("complaints.types.closed"),
                    icon: "mdi-email-lock",
                    value: 3,
                    color: "grey"
                },
                {
                    text: this.$t("complaints.types.spam"),
                    icon: "mdi-email-remove",
                    value: 4,
                    color: "red"
                }
            ],
            loading: false,
            search: "",
            headers: [
                {
                    text: this.$t("complaints.subject"),
                    value: "subject"
                },
                {
                    text: this.$t("complaints.details"),
                    value: "details"
                },
                {
                    text: this.$t("complaints.complaintDate"),
                    value: "complaintDate"
                },
                {
                    text: this.$t("complaints.duration"),
                    value: "duration"
                },
                {
                    text: this.$t("complaints.handlerName"),
                    value: "userHandler.username"
                },
                { text: "", value: "actions" }
            ],
            complaints: []
        };
    },
    created() {
        try {
            if (!this.isInRole(this.roles.complaint.window))
                this.redirectUnauthorizedUsers();
            this.loading = true;
            this.refreshTable();
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        viewItem(item) {
            try {
                this.$router.push({
                    name: "complaints.complaint",
                    params: {
                        id: item.complaintGuid
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        refreshTable() {
            try {
                axios
                    .get("Complaints/Get", {
                        params: {
                            State: this.selectedState
                        }
                    })
                    .then(response => {
                        this.complaints = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
