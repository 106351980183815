var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('Page-Header',{attrs:{"title":_vm.$t('complaints.complaints'),"icon":"mdi-badge-account-alert-outline"}}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary","mandatory":""},on:{"change":_vm.refreshTable},model:{value:(_vm.selectedState),callback:function ($$v) {_vm.selectedState=$$v},expression:"selectedState"}},_vm._l((_vm.listItems),function(item,i){return _c('div',{key:i},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":item.color},domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1),(i == 3)?_c('v-divider'):_vm._e()],1)}),0)],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-data-table',{staticClass:"pa-5",staticStyle:{"overflow":"hidden"},attrs:{"headers":_vm.headers,"items":_vm.complaints,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","loading":_vm.loading,"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"text-end",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.viewItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("preview"))+" ")])])],1)],1)]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"max-width":600,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("truncate")(item.details,40)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("truncate")(item.details,250)))])])]}},{key:"item.complaintDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.complaintDate,"yyyy-MM-DD hh:mm A"))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","outlined":""}},[_vm._v(_vm._s(_vm._f("fromNow")(item.stateDate)))])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }